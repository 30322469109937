<template>
    <div v-if="ready">
        <v-container fluid>
            <v-row>
                <v-col cols="12" class="d-flex align-center justify-space-between">
                    <h1 class="text-h4" style="flex-grow: 1">
                        {{ heading }}
                        <span v-if="ready && !loading">&nbsp;({{ hjemmetjenester.length }})</span>
                    </h1>
                    <v-btn color="primary" v-on:click="create">
                        Ny kobling
                        <v-icon right>mdi-plus</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-toolbar class="mt-6" color="grey lighten-4" elevation="4">
                    <v-col cols="3">
                        <v-text-field v-model="sok" label="Søk etter" append-icon="mdi-magnify" hide-details></v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-select v-model="type" :items="typer" item-text="navn" item-value="id" label="Type" hide-details></v-select>
                    </v-col>
                    <v-col cols="3">
                        <v-select v-model="status" :items="statuser" item-text="navn" item-value="id" label="Status" hide-details></v-select>
                    </v-col>
                </v-toolbar>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-data-table
                        :headers="headers"
                        :items="hjemmetjenester"
                        :custom-sort="customSort"
                        sort-by="brukerId"
                        :items-per-page="-1"
                        v-on:click:row="edit"
                        :item-class="classRow"
                        must-sort
                        hide-default-footer
                    >
                        <template v-slot:item.typeId="{ item }"> {{ item.type.navn }} </template>
                        <template v-slot:item.brukerId="{ item }"> {{ item.bruker.arkivert ? '(Bruker slettet)' : item.bruker.fulltNavn }} </template>
                        <template v-slot:item.frivilligId="{ item }">
                            {{ item.frivillig.arkivert ? '(Frivillig slettet)' : item.frivillig.fulltNavn }}
                        </template>
                        <template v-slot:item.opprettet="{ item }"> {{ item.opprettet | format('DD.MM.YYYY') }} </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        <visit-dialog ref="visitDialog" :hjemmetjenester="sortertListe" v-on:closed="closedDialog"></visit-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import VisitDialog from '@/pages/visit/VisitDialog.vue';

export default {
    name: 'VisitList',
    components: {
        VisitDialog,
    },
    props: {
        menyType: {
            type: String,
        },
    },
    data() {
        return {
            ready: false,
            loading: false,

            headers: [
                { text: 'Bruker', value: 'brukerId' },
                { text: 'Frivillig', value: 'frivilligId' },
                { text: 'Type', value: 'typeId' },
                { text: 'Opprettet', value: 'opprettet' },
            ],
            alleHjemmetjenester: [],
            hjemmetjenester: [],
            sortertListe: [],

            sok: '',
            type: '',
            typer: [],
            status: 'aktiv',
            statuser: [
                { id: '', navn: '(alle) ' },
                { id: 'aktiv', navn: 'Aktiv' },
                { id: 'avsluttet', navn: 'Avsluttet' },
            ],
        };
    },
    filters: {
        format(value, format) {
            if (value) {
                return value.format(format);
            }
            return '';
        },
    },
    watch: {
        menyType: function (value) {
            this.type = value;
        },
        type: function (value) {
            this.type = value;
            if (this.menyType && this.menyType != this.type && this.type) {
                this.$router.push('/hjemme/tjeneste/' + this.heading.toLowerCase());
            }
            if (this.ready) {
                this.filter();
            }
        },
        status: function (value) {
            this.status = value;
            if (this.ready) {
                this.update();
            }
        },
        sok: function (value) {
            this.sok = value;
            if (this.ready) {
                this.filter();
            }
        },
    },
    computed: {
        heading: function () {
            let heading = '';
            if (this.type) {
                this.typer.find((item) => {
                    if (item.id == this.type) {
                        heading = item.navn;
                    }
                });
            }
            if (!heading) {
                heading = 'Hjemmetjeneste';
            }
            return heading;
        },
    },

    /**
     * created
     */
    created: async function () {
        let response = await this.request({
            method: 'get',
            url: '/hjemmetjeneste/type',
        });
        if (response && Array.isArray(response)) {
            this.typer = response;
        }
        this.typer.unshift({ id: '', navn: '(alle)' });

        this.type = this.menyType;

        await this.update();
    },
    methods: {
        ...mapActions('api', ['request', 'formatVisit']),

        /**
         * update
         */
        update: async function () {
            try {
                this.loading = true;

                this.metoder = [];
                const response = await this.request({
                    method: 'get',
                    url: '/hjemmetjeneste',
                    params: {
                        status: this.status,
                    },
                });

                if (response && Array.isArray(response)) {
                    this.alleHjemmetjenester = [];
                    for (const hjemmetjeneste of response) {
                        this.alleHjemmetjenester.push(await this.formatVisit(hjemmetjeneste));
                    }
                }

                this.filter();
                this.loading = false;
                this.ready = true;
            } catch (e) {
                console.log(e);
            }
        },

        /**
         * filter
         */
        filter: function () {
            const hjemmetjenester = [];
            const sok = this.sok.toLowerCase();
            for (const hjemmetjeneste of this.alleHjemmetjenester) {
                if (
                    (!sok.length || hjemmetjeneste.bruker.sokeString.indexOf(sok) !== -1 || hjemmetjeneste.frivillig.sokeString.indexOf(sok) !== -1) &&
                    (!this.type || hjemmetjeneste.type.id == this.type)
                    /*((!this.status && person.status != 'SLUTTET') || (this.status && person.status == this.status))*/
                ) {
                    hjemmetjenester.push(hjemmetjeneste);
                }
            }
            this.hjemmetjenester = hjemmetjenester;
        },

        /**
         * customSort
         */
        customSort: function (items, index, isDesc) {
            if (index.length) {
                items.sort((a, b) => {
                    switch (index[0]) {
                        case 'brukerId':
                            a = a.bruker.fulltNavn;
                            b = b.bruker.fulltNavn;
                            break;

                        case 'frivilligId':
                            a = a.frivillig.fulltNavn;
                            b = b.frivillig.fulltNavn;
                            break;

                        case 'typeId':
                            a = a.type.navn;
                            b = b.type.navn;
                            break;

                        case 'opprettet':
                            if (!isDesc[0]) {
                                return a.opprettet.diff(b.opprettet);
                            } else {
                                return b.opprettet.diff(a.opprettet);
                            }

                        default:
                            a = a.id;
                            b = b.id;
                    }
                    if (!isDesc[0]) {
                        return a.localeCompare(b, 'no', { numeric: true, sensitivity: 'base' });
                    } else {
                        return b.localeCompare(a, 'no', { numeric: true, sensitivity: 'base' });
                    }
                });
            }
            this.sortertListe = items;
            return items;
        },

        /**
         * edit
         */
        edit: function (item, { index }) {
            this.$refs.visitDialog.edit(index);
        },

        /**
         * create
         */
        create: function () {
            this.$refs.visitDialog.create();
        },

        /**
         * closedDialog
         */
        closedDialog: async function () {
            await this.update();
        },

        classRow() {
            return 'v-data-table-item--link';
        },
    },
};
</script>
<style lang="scss">
.v-data-table-item--link {
    cursor: pointer;
}
</style>
