var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.ready)?_c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center justify-space-between",attrs:{"cols":"12"}},[_c('h1',{staticClass:"text-h4",staticStyle:{"flex-grow":"1"}},[_vm._v(" "+_vm._s(_vm.heading)+" "),(_vm.ready && !_vm.loading)?_c('span',[_vm._v(" ("+_vm._s(_vm.hjemmetjenester.length)+")")]):_vm._e()]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.create}},[_vm._v(" Ny kobling "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1)],1)],1),_c('v-row',[_c('v-toolbar',{staticClass:"mt-6",attrs:{"color":"grey lighten-4","elevation":"4"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"Søk etter","append-icon":"mdi-magnify","hide-details":""},model:{value:(_vm.sok),callback:function ($$v) {_vm.sok=$$v},expression:"sok"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":_vm.typer,"item-text":"navn","item-value":"id","label":"Type","hide-details":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":_vm.statuser,"item-text":"navn","item-value":"id","label":"Status","hide-details":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.hjemmetjenester,"custom-sort":_vm.customSort,"sort-by":"brukerId","items-per-page":-1,"item-class":_vm.classRow,"must-sort":"","hide-default-footer":""},on:{"click:row":_vm.edit},scopedSlots:_vm._u([{key:"item.typeId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.type.navn)+" ")]}},{key:"item.brukerId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.bruker.arkivert ? '(Bruker slettet)' : item.bruker.fulltNavn)+" ")]}},{key:"item.frivilligId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.frivillig.arkivert ? '(Frivillig slettet)' : item.frivillig.fulltNavn)+" ")]}},{key:"item.opprettet",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("format")(item.opprettet,'DD.MM.YYYY'))+" ")]}}],null,false,3505570059)})],1)],1)],1),_c('visit-dialog',{ref:"visitDialog",attrs:{"hjemmetjenester":_vm.sortertListe},on:{"closed":_vm.closedDialog}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }