<template>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" content-class="v-dialog--overlay">
        <v-toolbar dark color="primary">
            <v-btn icon dark @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ hjemmetjeneste.id ? 'Rediger' : 'Opprett' }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn v-if="hjemmetjeneste.id" text v-on:click="prev">
                <v-icon> mdi-chevron-left </v-icon>
                Forrige
            </v-btn>
            <v-btn v-if="hjemmetjeneste.id" text v-on:click="next">
                Neste
                <v-icon right> mdi-chevron-right </v-icon>
            </v-btn>
        </v-toolbar>
        <div style="background-color: #fff; position: relative">
            <br />
            <visit v-if="hjemmetjeneste.id" :id="hjemmetjeneste.id"> </visit>
            <new-visit v-else v-on:close="close" :dialog="true"> </new-visit>
        </div>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import Visit from '@/pages/visit/Visit.vue';
import NewVisit from '@/pages/visit/New.vue';

export default {
    name: 'DialogVisit',
    props: {
        hjemmetjenester: {
            type: Array,
            required: true,
        },
    },
    components: {
        Visit,
        NewVisit,
    },
    data() {
        return {
            hjemmetjeneste: {},
            dialog: false,
            index: -1,
        };
    },
    computed: {
        ...mapGetters('authenticate', ['user']),
    },
    methods: {
        /**
         * edit
         */
        edit: function (index) {
            this.hjemmetjeneste = this.hjemmetjenester[index];
            this.index = index;
            console.log(index);
            this.dialog = true;
        },

        /**
         * create
         */
        create: function () {
            this.hjemmetjeneste = {};
            this.dialog = true;
        },

        /**
         * close
         */
        close: function () {
            this.dialog = false;
            this.$emit('closed');
        },

        /**
         * prev
         */
        prev: async function () {
            if (this.index > 0) {
                const index = this.index - 1;
                await this.edit(index);
            }
        },

        /**
         * next
         */
        next: async function () {
            if (this.index < this.hjemmetjenester.length - 1) {
                const index = this.index + 1;
                await this.edit(index);
            }
        },
    },
};
</script>
<style lang="scss">
.v-dialog--fullscreen {
    background-color: #fff;
}
</style>
